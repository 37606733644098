@use 'src/scss/colors.scss';
@use 'src/scss/fonts.scss';
@import 'src/scss/responsive.scss';

.cardContainer {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  box-shadow: 0 1px 2px 0 #0000000a;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: colors.$white;
  opacity: 1;
  max-height: 400px;
  transition: 0.3s ease-out all;

  &Highlighted {
    @extend .cardContainer;
    background-color: colors.$brand-primary-1;
    border: 1px solid colors.$brand-primary-3;

    & .title {
      color: colors.$brand-primary-6;
    }
  }

  &Animated {
    overflow: hidden;
    animation: 0.5s ease heightChange forwards;
  }

  .cardInformation {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .cardTitles {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-left: 8px;
      max-width: 790px;

      .title {
        font-weight: fonts.$weight-semi-bold;
        font-size: fonts.$size-medium;
      }

      .subtitle {
        font-weight: fonts.$weight-normal;
        font-size: fonts.$size-medium;
        color: colors.$gray-8;
      }
    }
  }

  .cardActionsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;

    .cardActions {
      display: flex;
      gap: 24px;
    }
    .containersWrapper {
      display: flex;
      flex-wrap: wrap;
      max-width: 240px;
      row-gap: 8px;
      justify-content: flex-end;
    }

    .containerInformation {
      background-color: colors.$brand-primary-1;
      color: colors.$brand-primary-6;
      font-size: fonts.$size-small;
      font-weight: fonts.$weight-semi-bold;
      padding: 4px 8px;
      border-radius: 2px;
      width: fit-content;
      max-width: 240px;
      margin-left: 10px;
    }

    .containerInformationPlaceholder {
      min-height: 26px;
    }
  }
}

.icon {
  padding: 5px;
  display: grid;
  place-items: center;
  border: 0;
  background: none;

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    pointer-events: none;
  }
}
.cardContainer:hover {
  cursor: pointer;
}
.cardContainerNoHover:hover {
  cursor: default !important;
  .icon {
    pointer-events: none;
  }
}

.loadingCardContainer {
  height: 84px;
  background-color: colors.$white;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;

  .loadingCardInformation {
    display: flex;
    gap: 8px;
    width: 100%;

    .loadingCardTitles {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      .loadingSubtitle,
      .loadingTitle {
        border-radius: 2px;
        height: 22px;
        background-color: colors.$gray-4;
      }

      .loadingTitle {
        width: 120px;
      }

      .loadingSubtitle {
        width: 90%;
      }
    }
  }

  .loadingCardActionsWrapper {
    display: flex;
    gap: 8px;
  }

  .loadingCardActions {
    display: flex;
    gap: 16px;
  }

  .loadingIcon {
    height: 16px;
    width: 16px;
    background-color: colors.$gray-4;
    border-radius: 50%;
    margin-top: 2px;
  }
}

.loadingTitle,
.loadingSubtitle,
.loadingIcon {
  animation: color-change 1s infinite alternate;
}

@keyframes color-change {
  0% {
    background-color: colors.$gray-3;
  }

  100% {
    background-color: colors.$gray-4;
  }
}

@keyframes heightChange {
  from {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
  }
  to {
    padding-top: 16px;
    padding-bottom: 16px;
    max-height: 400px;
  }
}
