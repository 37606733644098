.customNovaNavigationHeader {
  position: relative;
  height: 72px;
  flex: 0 1 72px;
  width: 100%;

  [id~='nova-report-In-Development-Tag-root'] {
    margin-right: auto;
  }
}
