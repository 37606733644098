@use 'src/scss/fonts.scss';
@use 'src/scss/colors.scss';

.sectionTitle {
  font-size: fonts.$size-small;
  font-weight: fonts.$weight-semi-bold;
  text-transform: uppercase;
  letter-spacing: 0.96px;
  color: colors.$gray-7;
  margin-top: 16px;
}

.sectionCardNumber {
  font-size: fonts.$size-small;
  color: colors.$gray-7;
  font-weight: fonts.$weight-normal;
  line-height: 20px;
  margin-top: 16px;
}

.sectionContainer {
  .transitionWithChildrenGroupCards {
    display: flex;
    flex-direction: column;
  }
  display: flex;
  flex-direction: column;
  margin-top: 0;
  transform: translateY(0);

  &Animated {
    @extend .sectionContainer;
    animation: 0.5s  ease opacityChange forwards
  }

  &AnimatedSlideDown {
    @extend .sectionContainer;
    animation: 0.5s  ease heightChange forwards;
  }


  &AnimatedSlideUp {
    @extend .sectionContainer;
    animation: 0.5s ease heightChangeReverse forwards;
  }
}

@keyframes opacityChange {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes heightChange {
  0% {
    margin-top: -50%;
    transform: translateY(-50%);
    opacity: 0.25;
  }
  100% {
    margin-top: 0;
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes heightChangeReverse {
  0% {
    margin-top: 0;
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    margin-top: -50%;
    transform: translateY(-50%);
    opacity: 0.25;
  }
}

.sectionComponentsContainer {
  overflow: hidden;
}

.sectionCardWrapper {
  transition: all ease-in-out 300ms;
  max-height: 400px;
  transform: translateY(0);
  overflow: hidden;
  opacity: 1;
  margin-top: 16px;

  &Hidden {
    @extend .sectionCardWrapper;
    opacity: 0.2;
    margin-top: 0;
    max-height: 0;
    transform: translateY(-200px);
    visibility: hidden;
  }
}

.sectionHeading {
  transition: all ease-in-out 300ms;
  display: flex;
  justify-content: space-between;

  max-height: 200px;
  transform: translateY(0);
  overflow: hidden;
  opacity: 1;

  &Hidden {
    @extend .sectionHeading;
    opacity: 0;
    max-height: 0;
    transform: translateY(-200px);
    visibility: hidden;
  }
}

.dataCardExitActive {
  justify-content: space-between;
  box-shadow: 0 1px 2px 0 #0000000a;
  border-radius: 4px;
  background-color: colors.$white;
  opacity: 0;
  transition: 0.3s ease-out all;
  max-height: 0;
  padding: 0;
  margin: 0;
}
