@import './screenBreakpoints.scss';

@mixin small {
  @media screen and (max-width: #{$medium}) {
    @content;
  }
}

@mixin toMedium {
  @media screen and (max-width: #{$large}) {
    @content;
  }
}

@mixin smallToMedium {
  @media screen and (min-width: #{$medium}) and (max-width: #{$large}) {
    @content;
  }
}

@mixin medium {
  @media screen and (max-width: #{$large}) {
    @content;
  }
}

@mixin mediumToLarge {
  @media screen and (min-width: #{$extra-large}) and (max-height: #{$large}) {
    @content;
  }
}
@mixin large {
  @media screen and (max-width: #{$extra-large - 1px}) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: #{$extra-large}) {
    @content;
  }
}

@mixin xxl {
  @media only screen and (min-width: #{$xxl}) {
    @content;
  }
}
