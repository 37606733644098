@use 'src/scss/colors.scss';
@import 'src/scss/responsive.scss';

.paginationWrapper {
  position: absolute;
  width: 100%;
  bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @include medium {
    bottom: 16px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 24px;
  flex-direction: row;
  background: colors.$gray-10;
  opacity: 0.8;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  padding: 8px 24px;
  color: colors.$white;
}

.paginationDivider {
  height: 16px;
  background: colors.$gray-7;
}

.icon {
  cursor: pointer;
}

.borderTransparent {
  border-color: transparent !important;
}
