$size-small: 12px;
$size-medium: 14px;
$size-large: 16px;
$size-extra-large: 30px;

:export {
  small: $size-small;
  medium: $size-medium;
  large: $size-large;
  extraLarge: $size-extra-large;
}
