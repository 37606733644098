@use 'src/scss/colors.scss';
@use 'src/scss/fonts.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 220px);
}

.svgImg {
  margin-bottom: 8px;
}
.addDetails {
  max-width: 120px;
  text-align: center;
  color: colors.$gray-6;
  font-size: fonts.$size-small;
  font-weight: fonts.$weight-normal;
  line-height: 20px;
}
