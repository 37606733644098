@use 'src/scss/colors.scss';
@use 'src/scss/fonts.scss';
@import 'src/scss/responsive.scss';

.headerContent {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .headerTitle {
    display: flex;
    flex-direction: row;
    padding: 16px 0;
    align-items: center;
    gap: 16px;

    @include medium {
      .divider,
      .procedureType {
        display: none;
      }
    }
  }

  .avatarContainer {
    display: flex;
    align-items: center;
    position: relative;

    .liveIcon {
      background-color: colors.$blue-6;
      box-shadow: 0 0 0 2px colors.$gray-1;
      position: absolute;
      top: 15px;
      left: 24px;
    }
  }

  .basicInfo {
    font-size: 16px;
    font-weight: 600;
  }

  .procedureType {
    font-size: 16px;
    font-weight: 400;
  }

  .divider {
    width: 1px;
    height: 14px;
    opacity: 0.4;
    background: #bababa;
  }
}

.watchAndButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  .endProcedureButton {
    display: flex;
    align-items: center;
    height: 48px !important;
    width: 100% !important;
    &Loading {
      min-width: 190px;
      cursor: auto !important;
      background-color: colors.$brand-primary-6 !important;
    }
    & div {
      display: flex;
      gap: 8px;
    }
  }
}

.headerTitleWrapper {
  display: flex;
  gap: 24px;

  .headerTitle {
    gap: 8px;

    .title {
      font-weight: fonts.$weight-semi-bold;
    }
  }
}

.iconButton {
  border: none;
  outline: none;
  padding: 0;
  background: none;
  color: colors.$gray-10;

  &Submit {
    @extend .iconButton;
    color: colors.$green-6;
  }
}

.iconButton:disabled {
  background: none;
  color: colors.$gray-6;
}

.iconButton > * {
  cursor: pointer;
}

.iconButton:disabled > * {
  cursor: not-allowed;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconButton {
  border: none;
  outline: none;
  background: none;
  color: colors.$gray-10;
  padding: 12px;

  & > span > svg,
  & > svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    cursor: pointer;
  }

  &Submit {
    @extend .iconButton;
    color: colors.$green-6;
  }
}

.backButton {
  @extend .iconButton;

  &Disabled {
    @extend .backButton;
    cursor: not-allowed !important;
    color: colors.$gray-5;
  }
}
