@use 'src/scss/colors.scss' as colorsFile;

.avatarContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badgeSmall {
  background-color: colorsFile.$gray-5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  color: colorsFile.$white;
  font-weight: 600;
  font-size: 14px;
}

.liveIndicatorWrapperSmall {
  border-radius: 100%;
  opacity: 0.8;
  width: 32px;
  height: 32px;
  position: relative;
  background: colorsFile.$primary;

  svg {
    position: absolute;
    bottom: -6px;
    right: -6px;
    z-index: 999;
    background: colorsFile.$primary;
    border-radius: 100%;
    border: 2px solid colorsFile.$gray-10;
    padding: 3px;
    font-size: 22px;
    color: white;
  }

  .pulse1small {
    position: absolute;
    border-radius: 100%;
    background: colorsFile.$brand-primary-5;
    animation: pulsating-pulse-1-small 2s linear infinite;
  }

  @keyframes pulsating-pulse-1-small {
    25% {
      top: calc(50% - 24px / 2);
      left: calc(50% - 24px / 2);
      width: 28px;
      height: 28px;
      background: colorsFile.$brand-primary-5;
    }
    50% {
      top: calc(50% - 24px / 2);
      left: calc(50% - 24px / 2);
      width: 24px;
      height: 24px;
      background: colorsFile.$brand-primary-5;
    }
    75% {
      top: calc(50% - 38px / 2);
      left: calc(50% - 38px / 2);
      width: 38px;
      height: 38px;
      background: colorsFile.$brand-primary-5;
    }
    100% {
      top: calc(50% - 34px / 2);
      left: calc(50% - 34px / 2);
      width: 34px;
      height: 34px;
      background: colorsFile.$brand-primary-1;
    }
  }

  .pulse2small {
    position: absolute;
    border-radius: 100%;
    background: colorsFile.$brand-primary-1;
    animation: pulsating-pulse-2-small 2s linear infinite;
  }

  @keyframes pulsating-pulse-2-small {
    25% {
      top: calc(50% - 28px / 2);
      left: calc(50% - 28px / 2);
      width: 28px;
      height: 28px;
      background: colorsFile.$brand-primary-5;
    }
    50% {
      top: calc(50% - 20px / 2);
      left: calc(50% - 20px / 2);
      width: 20px;
      height: 20px;
      background: colorsFile.$brand-primary-5;
    }
    75% {
      top: calc(50% - 40px / 2);
      left: calc(50% - 40px / 2);
      width: 40px;
      height: 40px;
      background: colorsFile.$brand-primary-5;
    }
    100% {
      top: 0px;
      left: calc(50% - 32px / 2);
      width: 32px;
      height: 32px;
      background: colorsFile.$brand-primary-5;
    }
  }
}

.activeBadge {
  z-index: 1;
  position: absolute;
  background-color: colorsFile.$gray-5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  border: 2px solid colorsFile.$gray-1;
  color: colorsFile.$white;
  font-weight: 600;
  font-size: 12px;
  top: 2px;
  left: 2px;
}

.liveIndicatorWrapperSmall {
  border-radius: 100%;
  opacity: 0.8;
  width: 32px;
  height: 32px;
  position: relative;
  background: colorsFile.$primary;

  svg {
    position: absolute;
    bottom: -6px;
    right: -6px;
    z-index: 999;
    background: colorsFile.$primary;
    border-radius: 100%;
    border: 2px solid colorsFile.$gray-1;
    padding: 3px;
    font-size: 22px;
    color: white;
  }

  .pulse-1-small {
    position: absolute;
    border-radius: 100%;
    background: colorsFile.$primary;
    animation: pulsating-pulse-1-small 2s linear infinite;
  }

  @keyframes pulsating-pulse-1-small {
    25% {
      top: calc(50% - 24px / 2);
      left: calc(50% - 24px / 2);
      width: 28px;
      height: 28px;
      background: colorsFile.$brand-primary-9;
    }
    50% {
      top: calc(50% - 24px / 2);
      left: calc(50% - 24px / 2);
      width: 24px;
      height: 24px;
      background: colorsFile.$brand-primary-9;
    }
    75% {
      top: calc(50% - 38px / 2);
      left: calc(50% - 38px / 2);
      width: 38px;
      height: 38px;
      background: colorsFile.$brand-primary-9;
    }
    100% {
      top: calc(50% - 34px / 2);
      left: calc(50% - 34px / 2);
      width: 34px;
      height: 34px;
      background: colorsFile.$brand-primary-8;
    }
  }

  .pulse-2-small {
    position: absolute;
    border-radius: 100%;
    background: colorsFile.$primary;
    animation: pulsating-pulse-2-small 2s linear infinite;
  }

  @keyframes pulsating-pulse-2-small {
    25% {
      top: calc(50% - 28px / 2);
      left: calc(50% - 28px / 2);
      width: 28px;
      height: 28px;
      background: colorsFile.$brand-primary-9;
    }
    50% {
      top: calc(50% - 20px / 2);
      left: calc(50% - 20px / 2);
      width: 20px;
      height: 20px;
      background: colorsFile.$brand-primary-9;
    }
    75% {
      top: calc(50% - 40px / 2);
      left: calc(50% - 40px / 2);
      width: 40px;
      height: 40px;
      background: colorsFile.$brand-primary-8;
    }
    100% {
      top: 0px;
      left: calc(50% - 32px / 2);
      width: 32px;
      height: 32px;
      background: colorsFile.$brand-primary-9;
    }
  }
}
