@use 'src/scss/colors.scss' as colorsFile;

.loadingCard {
  width: 100%;
  height: 100%;
  animation: color-change 1s infinite alternate;
  .darkCard {
    height: 100%;
    width: 100%;
    background-color: colorsFile.$gray-4;
    border-radius: 4px;
  }

  .roundedCard {
    height: 100%;
    width: 100%;
    background-color: colorsFile.$gray-4;
    border-radius: 55px;
  }

  @keyframes color-change {
    0% {
      background-color: colorsFile.$gray-3;
    }

    100% {
      background-color: colorsFile.$gray-4;
    }
  }
}
