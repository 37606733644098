@use 'src/scss/colors.scss' as colorsFile;
@use 'src/scss/fonts.scss' as fontsFile;
@import 'src/scss/responsive.scss';

.wrapper {
  background-color: colorsFile.$app-background;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.scrollContainer {
  height: 0px;
  overflow: auto;
  scrollbar-gutter: stable both-edges;
  flex: 1 1 auto;
}
.container {
  margin: 0 auto;
  padding-top: 16px;
  height: max-content;
  padding-bottom: 40px;

  @include small {
    width: calc(100% - 48px);
  }
  @include medium {
    width: calc(100% - 96px);
  }
  @include large {
    width: calc(100% - 96px);
    max-width: 1120px;
  }
  @include xl {
    width: calc(100% - 96px);
    max-width: 1120px;
  }
}
