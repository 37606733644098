@use 'sass:map';
@use 'src/scss/colors.scss' as colorsFile;
@use 'src/scss/fonts.scss' as fontsFile;
@import 'src/scss/responsive.scss';

.previewReportContainer {
  height: 100%;
  background-color: colorsFile.$gray-7;
  display: flex;
  flex-direction: column;
}

.previewReportContentWrapper {
  height: 0px;
  flex: 1 1 auto;

  display: flex;
  flex-direction: row;
}

.previewReportContentContainer {
  width: 0;
  flex: 6 1 auto;
  height: 100%;
  position: relative;
}

.previewReportContentContainerInner {
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 32px;
  @include medium {
    padding: 16px;
  }
}
