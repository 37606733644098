@use 'src/scss/fonts.scss';
@use 'src/scss/colors.scss';

.tabs:first-child ::before {
  border-bottom: none !important;
}

.tabs {
  font-weight: fonts.$weight-semi-bold;
  :global .ant-tabs-tab {
    padding: 12px 8px;
  }
  :global .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
  }
  :global .ant-tabs-nav {
    margin: 0;
  }

  :global .ant-tabs-tabpane {
    overflow: hidden;
  }
}

.sectionWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.sectionTitleLoading {
  background-color: colors.$gray-4;
  height: 22px;
  animation: color-change 1s infinite alternate;
  margin: 16px 0;

  &.width33 {
    width: 33px;
  }
  &.width160 {
    width: 160px;
  }
  &.width70 {
    width: 70px;
  }
  &.width90 {
    width: 90px;
  }
  &.width50 {
    width: 50px;
  }
  &.rounded {
    height: 30px !important;
    border-radius: 15px;
  }
}

.alignTop {
  align-items: flex-start;
}

.gap16 {
  gap: 16px;
}
.gap24 {
  gap: 24px;
}

.flex {
  display: flex;
  align-items: center;
}

@keyframes color-change {
  0% {
    background-color: colors.$gray-3;
  }

  100% {
    background-color: colors.$gray-4;
  }
}
