$medium: 768px;
$large: 991px;
$extra-large: 1280px;
$xxl: 1440px;

:export {
  medium: $medium;
  large: $large;
  extraLarge: $extra-large;
  xxl: $xxl;
}
