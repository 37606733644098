@use 'src/scss/colors.scss';
@use 'src/scss/fonts.scss';

.background {
  height: 100%;
  background: colors.$gray-10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 62px;
}

.label {
  font-family: fonts.$secondary;
  font-style: normal;
  font-weight: fonts.$weight-normal;
  font-size: 24px;
  line-height: 32px;
  color: colors.$green-1;
}

.spinner [class~='ant-spin-dot'] {
  width: 60px;
  height: 60px;

  [class~='ant-spin-dot-item'] {
    width: 21.43px;
    height: 21.43px;
    transform: none;
    transform-origin: none;
    opacity: 1;
  }

  i:nth-of-type(1) {
    background-color: colors.$brand-primary-1;
  }

  i:nth-of-type(2) {
    background-color: colors.$brand-primary-3;
  }

  i:nth-of-type(3) {
    background-color: colors.$brand-primary-5;
  }

  i:nth-of-type(4) {
    background-color: colors.$brand-primary-6;
  }
}
