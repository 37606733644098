@use 'src/scss/colors.scss';
@use 'src/scss/fonts.scss';
@import 'src/scss/responsive.scss';

.pagesContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
}

.pageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pageWrapper canvas {
  max-width: 100% !important;
}

.pageNumber {
  font-style: normal;
  font-weight: fonts.$weight-normal;
  font-size: fonts.$size-medium;
  color: colors.$white;
}

.pageTitle {
  margin-bottom: 16px;
  font-weight: fonts.$weight-semi-bold;
  font-size: fonts.$size-medium;
  line-height: 18px;
  color: colors.$gray-7;
  text-align: center;
}

.selectedPage {
  border: 5px solid colors.$brand-primary-3;
}

.otherPage {
  opacity: 0.6;
  margin: 5px;
}

.page {
  cursor: pointer;
}

.sidebar {
  width: 0;
  width: 240px;
  padding: 32px;
  height: 100%;
  background-color: colors.$gray-10;
  overflow-y: auto;

  @include medium {
    width: 180px;
    padding: 16px;
  }

}

.loadingWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
