@use 'src/scss/colors.scss' as colorsFile;

.addButton {
  transition: all 0.3s, height 0s 0.3s;
  backdrop-filter: none;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  z-index: 1;

  &Expanded {
    @extend .addButton;
    backdrop-filter: blur(20px);
    background-color: rgba(colorsFile.$white, 0.5);
    transition: all 0.3s, height 0s;
    z-index: 10;
    height: 100%;

    ~ .mainFloatButton {
      transform: scale(1.4) rotate(0deg);
    }
  }
}

.addButtonOuterContainer {
  overflow: hidden;
  height: 0;
  width: 100vw;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: 0.3s ease all;
  &Expanded {
    width: 100vw;
    height: 100vh;
  }
}

.mainFloatButton {
  position: fixed;
  bottom: 40px;
  transform: scale(1.4) rotate(-45deg);
  transition: 0.1s ease all;

  &LeftAligned {
    @extend .mainFloatButton;
    left: 40px;
  }

  &RightAligned {
    @extend .mainFloatButton;
    right: 40px;
  }
}

.buttonContainer {
  overscroll-behavior: none;
  opacity: 0;
  transform: translateY(100%);
  position: absolute;
  bottom: 100px;
  max-height: calc(100vh - 120px);
  transition: all 0.3s ease-in-out, width 0s 0.3s;
  width: 0;
  overflow: hidden;

  &Expanded {
    @extend .buttonContainer;
    opacity: 1;
    transform: translateY(0px);
    overflow-y: auto;
    bottom: 0;
    width: 100vw;
    // max-height: 100vh;
    padding-bottom: calc(96px + (100vh - 100dvh));
    padding-top: 18px;
    transition: all 0.3s ease-in-out, width 0s;
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      max-height: 100dvh;
    }
    @supports not (-webkit-touch-callout: none) {
      /* CSS specific to devices other than iOS */
      max-height: 100vh;
    }
  }

  &Expanded::-webkit-scrollbar {
    width: 0;
  }
}

.buttonGroupContainer {
  position: relative;
  margin-right: 40px;
  margin-left: 40px;
}

.buttonGroupLabel {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.08em;
  text-align: right;
  text-transform: uppercase;

  color: colorsFile.$gray-9;

  white-space: nowrap;
  line-height: 40px;

  margin-left: auto;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  &Left {
    @extend .buttonGroupLabel;
    text-align: left;

    justify-content: flex-end;
    flex-direction: row-reverse;
  }
}

.skeletonButton {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #0068f0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: fixed;
  bottom: 32px;

  &LeftAligned {
    @extend .skeletonButton;
    left: 32px;
  }

  &RightAligned {
    @extend .skeletonButton;
    right: 32px;
  }
}
