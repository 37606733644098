@use 'src/scss/colors.scss';

.filterSectionWrapperLoading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .filterSectionLoading {
    animation: color-change 1s infinite alternate;
    background-color: colors.$gray-4;
    border-radius: 2px;
    height: 16px;
  }
  .width114 {
    width: 114px;
  }
  .width38 {
    width: 38px;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cardMargin {
  margin: 10px 0;
}

@keyframes color-change {
  0% {
    background-color: colors.$gray-3;
  }

  100% {
    background-color: colors.$gray-4;
  }
}
