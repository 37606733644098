@use 'src/scss/colors.scss';
@use 'src/scss/fonts.scss';

.errorStyles {
  background: colors.$red-6;
  border-color: colors.$red-6;
  &:hover {
    background-color: colors.$red-5;
    background: colors.$red-5 !important;
  }
}

.asyncModalTitle {
  font-weight: fonts.$weight-semi-bold;
  line-height: 24px;
  display: flex;
  gap: 16px;
}
