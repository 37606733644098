.labelSectionContainer {
  margin-top: 40px;
  margin-bottom: 8px;
  height: 16px;
  width: 100px;
}

.radioButtonRow {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 8px;
}

.finding {
  height: 56px;
}

.finding3 {
  width: calc((100% - 16px) / 3);
}

.finding6 {
  width: calc((100% - 40px) / 6);

  @media (max-width: 820px) {
    width: calc((100% - 16px) / 3);
  }
}
