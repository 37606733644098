@use '../../../../../../../../scss/colors' as colorsFile;
@use '../../../../../../../../scss/fonts';
@import '../../../../../../../../scss/responsive';

.inputError {
  color: colorsFile.$red-7;
  min-height: 16px;
  font-size: fonts.$size-small;
  line-height: 20px;
  font-weight: fonts.$weight-semi-bold;
}

.inputErrorPlaceholder {
  height: 20px;
  margin-top: 4px;
}

.inputNumber {
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
  border-color: transparent;
  transition: 0.3s all;

  & [class~='ant-input-number-input'] {
    color: colorsFile.$blue-7;
    font-weight: fonts.$weight-semi-bold;
    font-size: fonts.$size-small;
    line-height: 16px;
  }

  &:not(.error) [class~='ant-input-number-wrapper'],
  &:not(:focus) [class~='ant-input-number-wrapper'],
  &:not(:focus-visible) [class~='ant-input-number-wrapper'] {
    > :first-child {
      border-right: 1px solid colorsFile.$gray-4;
    }

    > :last-child {
      border-left: 1px solid colorsFile.$gray-4;
    }
  }

  & [class~='ant-input-number-group-addon'] {
    min-width: 40px;
    border-color: transparent;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      font-size: fonts.$size-large;
    }
  }

  & [class~='ant-input-number-input-wrap'],
  & [class~='ant-input-number-input'] {
    height: 100%;
  }

  /// focus
  &:focus:not(.error) {
    border-color: colorsFile.$blue-3;
    box-shadow: none;
  }

  &:focus:not(.error) [class~='ant-input-number-group-addon'],
  &:focus-within:not(.error) [class~='ant-input-number-group-addon'] {
    background-color: colorsFile.$blue-1;
    border-color: colorsFile.$blue-3;
    color: colorsFile.$blue-7;
  }

  &:hover:not(.error) [class~='ant-input-number-group-addon'],
  &:hover:not(.error) [class~='ant-input-number'],
  &:focus:not(.error),
  &:focus-within:not(.error) {
    border-color: colorsFile.$blue-3;
  }

  /// errors
  &.error [class~='ant-input-number-input'] {
    color: colorsFile.$red-7;
  }

  &.error [class~='ant-input-number'],
  &.error [class~='ant-input-number-group-addon'] {
    border-color: colorsFile.$red-3;
  }

  &.error [class~='ant-input-number-group-addon'],
  &:not([class~='ant-input-number-focused']).error [class~='ant-input-number'] {
    background-color: colorsFile.$red-1;
  }

  &.error [class~='ant-input-number-focused'] {
    background: #ffffff !important;
  }

  &:hover [class~='ant-input-number-group-addon'] {
    cursor: pointer;
  }

  &:hover [class~='ant-input-number-handler-wrap'] {
    display: none;
  }
}

.inputErrorVisible {
  display: block;
}

.findingSection {
  margin-bottom: 32px;

  .mb16 {
    margin-bottom: 16px;
  }

  .labelSectionContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    margin-bottom: 8px;
    height: 16px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .label {
    color: colorsFile.$gray-8;
    font-weight: fonts.$weight-semi-bold;
    font-size: fonts.$size-medium;
  }

  .radioButtonRow {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 16px;
  }

  .mb0 {
    margin-bottom: 0;
  }
}

.deepLevelBackground:not(.hide) > .findingSection:last-child {
  margin-bottom: 0 !important;
}
.deepLevelBackground:not(.hide) > .findingSection:last-of-type {
  padding-bottom: 0 !important;
}
.findingSection:not(.mb16):last-of-type {
  padding-bottom: 24px;
}

.containerNumberButton {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  gap: 8px;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  height: 56px;
  width: 100%;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
  background: colorsFile.$white;
  border: 1px solid transparent;
  transition: 0.3s all;
  color: colorsFile.$brand-primary-6;

  &:hover {
    cursor: pointer;
  }

  &:disabled:hover {
    cursor: not-allowed !important;
  }
}

.finding {
  height: 100%;
  position: relative;
  min-width: 140px;
  user-select: none;

  .indicator {
    position: absolute;
    right: 4.5px;
    top: 3px;
    line-height: 1;
    color: colorsFile.$brand-primary-6;
  }

  label > span {
    padding: 16px;
    height: 56px;
    font-size: fonts.$size-medium;
    line-height: 16px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  }

  @include medium {
    span,
    input[type='number'] {
      font-size: 0.9em;
    }
  }
}

.findingNumInput {
  width: calc((100% - 16px) / 3);
  max-width: 300px;

  @include medium {
    width: calc(100% / 1.5);
  }

  & [class~='ant-input-number'] {
    height: 100%;
  }
}

.finding3 {
  width: calc((100% - 16px) / 3);
}

.finding6 {
  width: calc((100% - 40px) / 6);

  @include medium {
    width: calc((100% - 16px) / 3);
  }
}

.blueBorderLeft:not(.hide) {
  border-left: 2px solid colorsFile.$blue-6;
  padding-left: 20px;
  margin-top: 8px;
  & .findingSection:first-of-type .labelSectionContainer {
    margin-top: 0 !important;
  }
}

.deepLevelBackground:not(.hide) {
  background-color: colorsFile.$gray-4;
  padding: 8px 16px 16px 16px;
}

.level5Blue {
  color: colorsFile.$blue-6 !important;
  font-weight: fonts.$weight-semi-bold;
}

/* new labels */
.level1Label {
  font-family: fonts.$secondary;
  font-size: fonts.$size-large;
  font-weight: fonts.$weight-semi-bold;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: colorsFile.$gray-9;
  margin-bottom: 8px;
}

.level2Label {
  //styleName: Section Header/Semibold 12px | 16px CAPS;
  font-family: fonts.$secondary;
  font-size: fonts.$size-small;
  font-weight: fonts.$weight-semi-bold;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: left;
  margin-bottom: -4px;
  color: colorsFile.$gray-8;
  text-transform: uppercase;
  flex: 0 0 100%;
}

.level4Label {
  font-family: fonts.$secondary;
  font-size: fonts.$size-small;
  font-weight: fonts.$weight-semi-bold;
  display: flex;
  align-items: center;
  height: 22px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.96px;
  text-align: left;
  color: colorsFile.$gray-8;
  margin-bottom: 8px;
}

.deepSection {
  position: relative;
  overflow: hidden;
  &:empty {
    overflow: visible;
  }
}

.deepSectionEnter {
  max-height: 0;
}

.deepSectionEnterActive {
  max-height: 1000px;
  transition: 300ms ease-in-out all;
}

.deepSectionExit {
  max-height: 1000px;
}

.deepSectionExitActive {
  max-height: 0;
  transition: 300ms ease-in-out all;
}

.opacityAnimation {
  animation: 200ms opacity-change ease-in-out;
}
.opacityAnimationBackwards {
  animation: 200ms opacity-change-backwards ease-in-out;
}
@keyframes opacity-change {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity-change-backwards {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.display {
  visibility: visible;
}

.hide > * {
  opacity: 0;
  height: 0;
  visibility: hidden;
  animation: 300ms ease-in-out animate-height-backwards;
}

.animateHeight {
  animation: 300ms ease-in-out animate-height;
}
.animateHeightBackwards {
  animation: 300ms ease-in-out animate-height-backwards;
}

@keyframes animate-height {
  from {
    max-height: 0;
  }
  to {
    max-height: 1000px;
  }
}
@keyframes animate-height-backwards {
  from {
    max-height: 1000px;
  }
  to {
    max-height: 0px;
  }
}

.option {
  overflow: hidden;
}

.mb32 {
  margin-bottom: 32px;
}
