@use 'src/scss/colors.scss' as colorsFile;
@use 'src/scss/fonts.scss';

.loadingWrapper {
  position: relative;
  max-width: 100%;
  margin: auto;
}

.spinnerContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: colorsFile.$primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 60px;
  }

  p {
    font-weight: fonts.$weight-semi-bold;
    margin-top: 20px;
  }
}

.loadingText {
  font-size: fonts.$size-medium;
  font-weight: fonts.$weight-semi-bold;

  @media (max-height: 800px) {
    font-size: 10px;
  }
}
