@use 'src/scss/colors.scss';
@use 'src/scss/fonts.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  transition: 0.3s all;

  width: 100%;
  padding: 10px 16px 20px;
  background-color: colors.$gray-2;
  border-radius: 2px;
}

.wrapperWhenEditing {
  background-color: colors.$gray-1 !important;
  border-left: 2px solid colors.$brand-primary-6;
}

.itemTextBold {
  font-weight: fonts.$weight-semi-bold !important;
}

.itemsWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 16px;

  .itemText {
    font-weight: fonts.$weight-normal;
    font-size: fonts.$size-medium;
    line-height: 22px;
    color: colors.$gray-9;
    cursor: pointer;

    width: fit-content;
    border-radius: 2px;
    padding: 2px 4px;
    border: 1px solid transparent;
  }

  .verticalDivider {
    margin: 7px 0 7px 0;
    height: 8px;
    border-left: 1px solid colors.$brand-primary-6;
  }
}

.itemDropdown {
  [class~='ant-select-auto-complete'] {
    max-width: 170px;

    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;
  }

  [class~='ant-input'] {
    min-width: 102px;
    max-width: 142px;
  }
}

.inputField {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 130px;
}

.itemLabelText {
  font-style: normal;
  font-weight: fonts.$weight-normal;
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 2px;
  padding-left: 5px;

  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: colors.$gray-8;
}

.imagesLabelText {
  font-style: normal;
  font-weight: fonts.$weight-normal;
  font-size: 12px;
  margin-bottom: 2px;
  padding-left: 5px;
  transition: 0.3s color;
  color: colors.$gray-6;
}
.labelTextActive {
  color: colors.$brand-primary-6;
}

.todoLabel {
  // revert parent padding + border
  margin: -3px -5px;
  padding: 2px 4px;
  width: fit-content;

  background: colors.$yellow-1;
  border: 1px solid colors.$yellow-3 !important;
  border-radius: 2px;
}

.todoNumberLabel {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

.mt10 {
  margin-top: 10.5px;
}

.mt12 {
  margin-top: 12px;
}

.noTransition {
  transition: none;
}

.searchBarActivePadding {
  padding: 2px 0 0 0;
}

.highlight {
  background-color: colors.$brand-primary-1;
}

.imageOuterWrapper {
  max-width: fit-content;
  margin-top: 16px;
}

.imagesLabelWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 21px;
}
.imageWrapper {
  display: flex;
  flex-wrap: wrap;
  border-radius: 2px;
  gap: 8px;
  width: fit-content;
}
.imageWrapperBorder {
  padding: 8px;
  border: 1px solid colors.$gray-5;
}

.searchBarDropdown {
  min-width: 130px !important;
  max-width: 300px !important;

  [class~='ant-select-item-group'] {
    font-family: fonts.$secondary;
    font-style: normal;
    font-weight: fonts.$weight-normal;
    font-size: fonts.$size-small;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: colors.$gray-7;
    border-bottom: 1px solid colors.$gray-4;
    margin-top: 8px;
  }

  [class~='ant-select-item-option'] {
    font-family: fonts.$secondary;
    font-style: normal;
    font-weight: fonts.$weight-normal;
    font-size: fonts.$size-medium;
    line-height: 22px;
    color: colors.$gray-9;
  }

  [class~='ant-select-item-option-grouped'] {
    padding-inline-start: 12px;
  }
}

.hoverHighlight {
  background: colors.$blue-1;
  border: 1px solid colors.$blue-3 !important;
}

[class~='ant-input-suffix'] {
  cursor: auto;
}

[class~='ant-select-single']
  [class~='ant-select-selector']
  [class~='ant-select-selection-search-input'] {
  cursor: auto;
}

.level4Wrapper {
  margin-top: 22px;
}

.subtitle {
  line-height: 22px;
  font-weight: fonts.$weight-semi-bold;
}