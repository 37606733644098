@use 'src/scss/colors.scss' as colorsFile;

.developmentTag {
  border: 1px solid #ffffff;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  border-radius: 2px;
  height: 24px;

  &.autoHeight {
    margin: auto 0px;
  }
  &.marginLeft {
    margin-left: 18px;
  }
}
.headerStyle{
  border: 1px solid colorsFile.$blue-6;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 12px;
  color: colorsFile.$blue-6;
  border-radius: 2px;
  height: 24px;
  margin-left: 20px;
}