@use 'src/scss/colors.scss' as colorsFile;

.previewReportWrapper {
  display: flex;
  width: 100%;
  height: 56px;
  background-color: colorsFile.$gray-10;
  justify-content: space-between;
  border: none;
}

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    color: colorsFile.$gray-5;
    margin-left: 10px;
  }
}

.rightSideWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.infoText {
  color: white;
  margin-left: 8px;
}

.sideBarButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  border-radius: 2px;
  color: white;
  background-color: colorsFile.$brand-primary-6;
  cursor: pointer;
  border: none;
  font-size: 14px;

  &:focus,
  &:hover {
    background-color: colorsFile.$brand-primary-5;
    color: white !important;
  }

  &:disabled:hover {
    background-color: colorsFile.$gray-5;
  }
  &Loading,
  &Loading:disabled,
  &Loading:disabled:hover,
  &Loading:hover {
    background-color: colorsFile.$brand-primary-6 !important;
    min-width: 120px !important;
    cursor: auto !important;
  }
}

.sideBarButtonWhite {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  border-radius: 2px;
  color: colorsFile.$gray-9;
  background-color: colorsFile.$gray-1;
  cursor: pointer;
  border: 1px solid colorsFile.$gray-5;
  font-size: 14px;

  &:focus,
  &:hover {
    background-color: colorsFile.$gray-3;
    color: colorsFile.$gray-9 !important;
  }

  &:disabled:hover {
    background-color: colorsFile.$gray-5;
  }
}

.borderTransparent {
  border-color: transparent !important;
}
