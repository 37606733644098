@use 'src/scss/colors.scss' as colorsFile;

.buttonsContainer {
  position: relative;
  margin-left: auto;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-bottom: 8px;

  &Left {
    @extend .buttonsContainer;
    flex-direction: row-reverse;
  }
}

.buttonContainer {
  margin-bottom: 8px;
  position: relative;
}

.buttonInnerContainer {
  display: inline-flex;
  transition: 0.2s ease-in all;

  &Left {
    @extend .buttonInnerContainer;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
}

.buttonInnerContainer:hover {
  color: colorsFile.$brand-primary-5;
}

.buttonInnerContainer:hover .buttonLabel {
  color: colorsFile.$brand-primary-5;
}

.buttonInnerContainer:hover .terminologyButton {
  background-color: colorsFile.$brand-primary-5;

  svg path {
    background-color: colorsFile.$brand-primary-5;
    fill: colorsFile.$white;
  }
}

.buttonLabel {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: rgba(39, 41, 46, 1);
  line-height: 40px;
  padding-right: 12px;
  padding-left: 12px;
  cursor: pointer;
}

.terminologyButton {
  border: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  background-color: #ffffff;
  color: rgba(39, 41, 46, 1);
  display: grid;
  place-items: center;
}

.terminologyButton:hover {
  background-color: colorsFile.$brand-primary-5;
  border-color: colorsFile.$brand-primary-5;

  svg path {
    background-color: colorsFile.$brand-primary-5;
    fill: colorsFile.$white;
  }
}
