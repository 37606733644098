@use 'src/scss/colors.scss' as colorsFile;

.banner {
  flex: 0 1 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: colorsFile.$red-7;
  .bannerText {
    font-size: 10px;
    font-weight: 600;
    color: colorsFile.$white;
    overflow-y: hidden;
  }
}
