@use 'src/scss/fonts.scss';
@use 'src/scss/colors.scss';

.toggleContainer {
  gap: 8px;
  display: flex;
  align-items: center;
}
.containersOnly {
  font-size: fonts.$size-medium;
  font-weight: fonts.$weight-semi-bold;
  line-height: 22px;
  color: colors.$gray-9;
}

.containersOnly.disabled,
.toggle:disabled {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}
